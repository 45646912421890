import React from 'react'

import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
}))

const Main = ({ children }) => {
  const classes = useStyles()
  return (
    <Box component="main" className={classes.root}>{children}</Box>
  )
}

export default Main
