import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

import Drawer from '../drawer'
import Logo from '../logo'

const useStyles = makeStyles(theme => ({
  root: {},
  link: {
    color: theme.palette.common.black,
    textDecoration: 'none',
    textTransform: 'uppercase',
    padding: `0 ${theme.spacing(1)}px`,
    margin: `0 ${theme.spacing(1)}px`,
    "&:hover": {
      textDecoration: 'underline',
    }
  },
  logo: {
    width: 140,
    height: 43,
    display: "flex",
  }
}))

const Header = ({ showLogo, logo }) => {
  const classes = useStyles()
  const [drawer, setDrawer] = useState(false)
  return (
    <>
      <AppBar position="static" color="inherit" elevation={0}>
        <Toolbar>
          <Container maxWidth="lg">
            <Grid container>
            <Grid item xs={3}>
              {showLogo && (
                <Link to="/">
                  <Logo className={classes.logo} />
                </Link>
              )}
            </Grid>
            <Grid item xs={9}>
              <Box display="flex" justifyContent="flex-end">
                <Hidden smDown>
                  <Typography variant="body1">
                    <Link to="/o-nas" className={classes.link}>O nas</Link>
                  </Typography>
                  <Typography variant="body1">
                    <Link to="/portfolio" className={classes.link}>Portfolio</Link>
                  </Typography>
                  <Typography variant="body1">
                    <Link to="/kontakt" className={classes.link}>Kontakt</Link>
                  </Typography>
                </Hidden>
                <Hidden mdUp>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={() => setDrawer(true)}
                    className={classes.menuButton}
                    aria-label="menu"
                  >
                    <MenuIcon />
                  </IconButton>
                </Hidden>
              </Box>
            </Grid>
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>
      <Drawer drawer={drawer} setDrawer={setDrawer} />
    </>
  )
}

Header.defaultProps = {
  logo: "#000",
  showLogo: true
}

export default Header
