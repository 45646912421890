import React from "react"
import { ThemeProvider, createTheme, CssBaseline } from "@material-ui/core"

import  Main from "../main"
import  Header from "../header"
import  Footer from "../footer"

import '@fontsource/lato';

// import { makeStyles } from "@material-ui/core/styles"
// import { BlackLogoIcon, MenuIcon, WhiteLogoIcon } from "../../icons"

const theme = createTheme({
  breakpoints: {
    values: { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1412 },
  },
  typography: {
    fontFamily: ["Lato", "sans-serif"].join(", "),
    body1: {
      fontFamily: ["Lato", "sans-serif"].join(", "),
    },
    body2: {
      fontFamily: ["Lato", "sans-serif"].join(", "),
    },
    h1: {
      fontWeight: "900",
      fontFamily: ["Lato", "sans-serif"].join(", "),
      textTransform: "uppercase",
    },
    h2: {
      fontWeight: "600",
      fontFamily: ["Lato", "sans-serif"].join(", "),
    },
    h3: {
      fontWeight: "400",
      fontFamily: ["Lato", "sans-serif"].join(", "),
    },
    h4: {
      fontWeight: "600",
      fontFamily: ["Lato", "sans-serif"].join(", "),
    },
    h5: {
      fontFamily: ["Lato", "sans-serif"].join(", "),
    },
  },
  palette: {
    primary: {
      main: "#363654",
    },
    secondary: {
      main: "#FC9E11",
    },
    text: {
      primary: "#363654",
      secondary: "#fff",
      disabled: "#333399",
    },
  },
  overrides: {
    MuiTypography: {
      body1: {
        fontSize: "16px",
        "@media (max-width: 599px)": {
          fontSize: "14px",
        },
      },
      h1: {
        "@media (max-width: 599px)": {
          fontSize: "25px",
        },
      },
      colorTextSecondary: {
        "@media (max-width: 599px)": {
          fontSize: "25px",
        },
      },
      h4: {
        "@media (max-width: 599px)": {
          fontSize: "18px",
        },
      },
    },
    MuiCssBaseline: {
      "@global": {
        "html, body, #___gatsby, #gatsby-focus-wrapper": {
          height: "100%",
        },
        html: {
          WebkitFontSmoothing: "auto",
        },
        body: {
          backgroundColor: "#fff",
        },
        "#gatsby-focus-wrapper": {
          width: "100%",
          display: "flex",
          flexDirection: 'column'
        },
        "*": {
          wordBreak: "break-word",
        },
        a: {
          fontFamily: `"Lato", "sans-serif" !important`,
        },
      },
    },
  },
})

// const useStyles = makeStyles(theme => ({}))

const Layout = ({ children, location, showLogo }) => {
  const isHome = location && location.pathname && location.pathname === "/"
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {isHome ? null : <Header showLogo={showLogo} />}
      <Main>{children}</Main>
      {isHome ? null : <Footer />}
    </ThemeProvider>
  )
}

export default Layout
