import React from 'react'

const Logo = ({ fill, className }) => {
  return (
    <svg width="500" height="155" viewBox="0 0 500 155" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g fill={fill} fillRule="nonzero">
        <path d="M359.809 8.356h-90.975V28.93h56.65l-67.81 122.353h102.135V130.71h-67.817zM143.588 96.378h-42.292V8.383H80.218v87.995H21.085V8.383H0v142.9h21.085V116.95h59.133v34.331h21.092v-34.331h32.94l-15.56 34.331h22.742l14.953-34.331h59.133l14.36 34.331h22.741L187.312 0l-43.724 96.378zm21.74 0l21.145-48.595 20.39 48.595h-41.536z"/>
        <path d="M498.117 96.378a83.03 83.03 0 0 0 1.637-16.453c0-41.999-30.388-74.14-71.739-74.14-41.35 0-71.74 32.141-71.74 74.14s30.39 74.147 71.74 74.147c27.926 0 50.832-14.672 62.756-37.121h-24.68c-9.031 10.668-22.353 16.978-38.076 16.978-29.563 0-50.655-22.285-50.655-54.004 0-31.719 21.092-54.004 50.655-54.004 29.563 0 50.655 22.285 50.655 53.997.03 5.55-.657 11.08-2.047 16.453l21.494.007z"/>
      </g>
    </svg>
  )
}

Logo.defaultProps = {
  fill: "#000"
}

export default Logo
