import React from "react"
import Box from "@material-ui/core/Box"
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import MuiSwipeableDrawer from "@material-ui/core/SwipeableDrawer"

import { makeStyles, withStyles } from "@material-ui/core/styles"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Typography } from "@material-ui/core";

// import { FacebookIcon, InstagramIcon } from "../../icons"



const SwipeableDrawer = withStyles(theme => ({
  paper: {
    width: '100%',
    maxWidth: theme.spacing(50)
  }
}))(MuiSwipeableDrawer)

const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.common.black,
    transition: "color 150ms linear",
    textDecoration: 'none',
    "&:hover": {
      textDecoration: 'underline',
    },
    "&:hover:active": {
      textDecoration: 'underline',
    }
  },
  drawer: {
    position: 'relative'
  },
  close: {
    top: theme.spacing(1),
    right: theme.spacing(1),
    color: theme.palette.common.black,
    position: "absolute",
  },
  socials: {
    color: theme.palette.common.black,
    marginLeft: theme.spacing(0.4),
    marginRight: theme.spacing(0.4),
  }
}))

const Drawer = ({ drawer, setDrawer }) => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query {
      datoCmsSocialLink {
        facebook
        instagram
      }
      datoCmsHomePage {
        title
      }
      datoCmsPortfolioPage {
        title
      }
      datoCmsStudioPage {
        title
      }
      datoCmsOfferPage {
        title
      }
      datoCmsContactPage {
        title
      }
    }
  `)

  const links = {
    "/portfolio/": data.datoCmsPortfolioPage.title,
    "/o-nas/": data.datoCmsStudioPage.title,
    "/kontakt/": data.datoCmsContactPage.title,
  }

  return (
    <SwipeableDrawer
      anchor="right"
      open={drawer}
      onOpen={() => null}
      onClose={() => setDrawer(false)}
      className={classes.drawer}
    >

      <IconButton className={classes.close} onClick={() => setDrawer(false)}>
        <CloseIcon />
      </IconButton>
      <Box display="flex" alignItems="center" flexDirection="column" mt={20}>
        {Object.keys(links).map(x => (
          <Typography key={x} variant="h4" gutterBottom>
            <Link  to={x} className={classes.link}>
              {links[x]}
            </Link>
          </Typography>
        ))}
      </Box>
      <Box display="flex" justifyContent="center" mt={4} px={10}>
        <a href={data.datoCmsSocialLink.facebook} rel="noopener noreferrer" className={classes.socials}>
          <FacebookIcon />
        </a>
        <a href={data.datoCmsSocialLink.instagram} rel="noopener noreferrer" className={classes.socials}>
          <InstagramIcon />
        </a>
      </Box>
    </SwipeableDrawer>
  )
}

export default Drawer