import React from "react"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import PhoneIcon from '@material-ui/icons/Phone';
import Typography from '@material-ui/core/Typography';
import IconButton from "@material-ui/core/IconButton"
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import { makeStyles } from "@material-ui/core/styles"
import { Link, graphql, useStaticQuery } from "gatsby"

const useStyles = makeStyles(theme => ({
  footer: {
    color: "#000",
    width: "100%",
    marginTop: theme.spacing(12),
    padding: theme.spacing(1)
  },
  link: {
    color: theme.palette.common.black,
    textDecoration: 'none',
    "&:hover": {
      textDecoration: 'underline',
    }
  }
}))

const Footer = () => {
  const classes = useStyles()
  const { 
    datoCmsSocialLink: { 
      facebook, 
      instagram 
    }, 
    datoCmsDetail: {
      emailAddress,
      phoneNumber
    } 
  } = useStaticQuery(graphql`
    query {
      datoCmsSocialLink {
        facebook
        instagram
      }
      datoCmsDetail {
        emailAddress
        phoneNumber
        address
      }
    }
  `)

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Box display="flex" justifyContent="center" alignItems="center">
          <IconButton
            size="small"
            href={facebook}
            rel="noopener noreferrer"
            target="_blank"
            className={classes.link}
          >
            <FacebookIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            href={instagram}
            rel="noopener noreferrer"
            target="_blank"
            className={classes.link}
          >
            <InstagramIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            href={`tel:${phoneNumber}`}
            rel="noopener noreferrer"
            target="_blank"
            className={classes.link}
          >
            <PhoneIcon fontSize="small" />
          </IconButton>
          <IconButton
            size="small"
            href={`mailto:${emailAddress}`}
            rel="noopener noreferrer"
            target="_blank"
            className={classes.link}
          >
            <MailOutlineIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box mt={1.5} display="flex" justifyContent="center" alignItems="center">
          <Typography variant="body2">
            <Link to="/" className={classes.link}>hazostudio.pl</Link> © - Wszelkie prawa zastrzeżone |{" "}
            <Link to="/polityka-prywatnosci/" className={classes.link}>Polityka prywatności</Link> | projekt:{" "}
            <a
              href="https://mazak.studio"
              rel="noreferrer noopener"
              target="_blank"
              className={classes.link}
            >
              mazak.studio
            </a>
          </Typography>
        </Box>
      </Container>
    </footer>
  )
}

export default Footer